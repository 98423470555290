import React from "react";
import { Tooltip, TooltipProps, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { gds_theme, gds_typo } from "@ui/goono/styles/theme";

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 220,
    padding: "8px",
    borderRadius: gds_theme.round_4,
    background: gds_theme.gray_9_title,
    color: gds_theme.white,
    ...gds_typo.caption1_r,
  },
  arrow: {
    color: gds_theme.gray_9_title,
  },
  hidden: {
    display: "none",
    visibility: "hidden",
  },
}));
export type ArrowToolTipProps = {
  /** "overflow: hidden" 처리 되었을때만 tooltip 등장  */
  viewOverflow?: boolean;
  rootClass?: string;
} & TooltipProps;

/**
 *  hover시 등장하는 Tooltip
 **  children에 함수형 CustomComponent 바로 사용 안됨
 **  html tag, mui component 등으로 wrap 필요
 */
const ArrowToolTip: React.FC<ArrowToolTipProps> = (
  props: ArrowToolTipProps
) => {
  const { viewOverflow, children, rootClass, ...rest } = props;
  const [overflowText, setOverflowText] = React.useState<boolean>(true);
  const classes = useStyles();

  const onOverflowTooltip = (evt: any) => {
    if (viewOverflow === false || viewOverflow === undefined) return;
    const elem = evt.target as HTMLElement;
    elem.scrollWidth > elem.offsetWidth || elem.scrollHeight > elem.offsetHeight
      ? setOverflowText(true)
      : setOverflowText(false);
  };
  return (
    <Tooltip
      {...rest}
      arrow
      classes={{
        tooltip: clsx(
          classes.root,
          rootClass,
          overflowText ? undefined : classes.hidden
        ),
        arrow: classes.arrow,
      }}
      onOpen={(evt) => {
        onOverflowTooltip(evt);
      }}
    >
      {children}
    </Tooltip>
  );
};

export default ArrowToolTip;
