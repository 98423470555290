import { ICreateDocumentShareLink } from "@goono-commons/dataRoom/request/documentShareLink";
import { InternalError } from "@redwit-commons/utils/exception2";
import {
  mkReducer,
  StateMachine3,
  StateMachineAction,
  transition,
} from "@redwit-react-commons/reducers/state3";
import { Dispatch } from "react";

export enum NDACustomScreenStateStatus {
  INIT = "NDACustomScreenStateStatus::INIT",
}

export enum NDACustomScreenActionKind {
  TRY_UPDATE_STATE = "NDACustomScreenActionKind::TRY_UPDATE_STATE",
}

export type NDACustomScreenError = never;

export type NDACustomScreenState = {
  readonly status: NDACustomScreenStateStatus.INIT;
  readonly documentBoxId: string;
  readonly documentBoxFolderId: string | undefined;
  readonly documentId: string;
  readonly documentName: string;
  /** 어디서 접근했는지 */
  readonly accessedPath: string;
  readonly ndaId: string;
  readonly shareLinkCreationInfo: ICreateDocumentShareLink | undefined;
};

export type NDACustomScreenAction = {
  readonly kind: NDACustomScreenActionKind.TRY_UPDATE_STATE;
  readonly newState: Partial<NDACustomScreenState>;
};

const smid = "NDA_SIGNATURE_SCREEN_STATE_MACHINE3";

export type NDACustomScreenStateMachineType = StateMachine3<
  NDACustomScreenStateStatus,
  NDACustomScreenState,
  NDACustomScreenActionKind,
  NDACustomScreenAction,
  NDACustomScreenError
>;

export const NDACustomScreenStateMachine: NDACustomScreenStateMachineType =
  new StateMachine3<
    NDACustomScreenStateStatus,
    NDACustomScreenState,
    NDACustomScreenActionKind,
    NDACustomScreenAction,
    NDACustomScreenError
  >(
    smid,
    {
      status: NDACustomScreenStateStatus.INIT,
      documentBoxId: ``,
      documentBoxFolderId: undefined,
      documentId: ``,
      documentName: ``,
      accessedPath: ``,
      ndaId: ``,
      shareLinkCreationInfo: undefined,
    },
    [
      transition(
        NDACustomScreenStateStatus.INIT,
        NDACustomScreenStateStatus.INIT,
        NDACustomScreenActionKind.TRY_UPDATE_STATE
      ),
    ]
  );

export type DispatchNDACustomScreenAction = Dispatch<
  StateMachineAction<
    NDACustomScreenStateStatus,
    NDACustomScreenState,
    NDACustomScreenActionKind,
    NDACustomScreenAction,
    NDACustomScreenError
  >
>;

export default mkReducer<
  NDACustomScreenStateStatus,
  NDACustomScreenState,
  NDACustomScreenActionKind,
  NDACustomScreenAction,
  NDACustomScreenError
>(NDACustomScreenStateMachine);

export const doNDACustomScreenAction = (
  dispatch: DispatchNDACustomScreenAction,
  nextAction: NDACustomScreenAction,
  onResolve: () => void = () => {},
  onReject: (err: NDACustomScreenError | InternalError) => void = () => {}
) => {
  dispatch(
    NDACustomScreenStateMachine.newTryAction(nextAction, onResolve, onReject)
  );
};

export const doNDACustomScreenActionAsync = (
  dispatch: DispatchNDACustomScreenAction,
  nextAction: NDACustomScreenAction
) => {
  return new Promise<void>((resolve, reject) => {
    dispatch(
      NDACustomScreenStateMachine.newTryAction(nextAction, resolve, reject)
    );
  });
};

export const resetNDACustomScreen = (
  dispatch: DispatchNDACustomScreenAction
) => {
  dispatch(NDACustomScreenStateMachine.newResetAction());
};
