import React from "react";
import { ButtonBase, FormLabel } from "@material-ui/core";
import useStyles from "./styles/SolidButton";
import Flex from "../layouts/Flex";
import clsx from "clsx";

export type ButtonType =
  | "primary"
  | "primary_line"
  | "gray"
  | "white"
  | "gray_line"
  | "cool_gray"
  | "cool_gray_line"
  | "green"
  | "danger"
  | "success"
  | "error"
  | "navy";
export type ButtonSize = "xl" | "lg" | "md" | "sm";
export type SolidButtonProps = {
  customId?: string;
  /** 버튼 컬러 타입 */
  buttonType?: ButtonType;
  /**
   * 버튼 크기 - height로 판단 [48,40,32,28] 순서
   * */
  buttonSize?: ButtonSize;
  /** width: 100% 설정 */
  fullWidth?: boolean;
  /*
   * icon + text에서 text가 사라지는 breakPoint 설정
   */
  simpleMode?: ButtonSize;
  /**비활성화 */
  disabled?: boolean;
  className?: string;
  dataTestid?: string;
  onClick?: (evt: React.MouseEvent<HTMLButtonElement>) => void;
  /**form label for 설정 */
  htmlFor?: string;
  /** 내부 아이콘의 Class */
  iconClass?: string;
  /** 좌측 아이콘, svg 추천 */
  startIcon?: React.ReactNode;
  /** 우측 아이콘, svg 추천 */
  endIcon?: React.ReactNode;
  /** 버튼 안의 내용 - 텍스트 */
  children?: React.ReactNode;
  submit?: boolean;
};

/**
 * - Background & Border 가 있는 버튼
 */ const SolidButton: React.FC<SolidButtonProps> = ({
  customId,
  buttonType = "gray_line",
  buttonSize = "lg",
  fullWidth,
  simpleMode,
  disabled,
  className,
  dataTestid,
  onClick,
  htmlFor,
  iconClass,
  startIcon,
  endIcon,
  children,
  submit,
}) => {
  const classes = useStyles();

  const sizeClasses: Record<ButtonSize, string> = {
    xl: classes.btn_xl,
    lg: classes.btn_lg,
    md: classes.btn_md,
    sm: classes.btn_sm,
  };

  const colorClasses: Record<ButtonType, string> = {
    primary: classes.primary,
    primary_line: classes.primary_line,
    gray: classes.gray,
    white: classes.white,
    gray_line: classes.gray_line,
    cool_gray: classes.cool_gray,
    cool_gray_line: classes.cool_gray_line,
    green: classes.green,
    danger: classes.danger,
    navy: classes.navy,
    success: classes.success,
    error: classes.error,
  };

  const simpleModeClasses: Record<ButtonSize, string> = {
    xl: classes.simple_xl,
    lg: classes.simple_lg,
    md: classes.simple_md,
    sm: classes.simple_sm,
  };

  const noIconClasses: Record<ButtonSize, string> = {
    xl: "",
    lg: classes.noIcon_lg,
    md: classes.noIcon_md,
    sm: classes.noIcon_sm,
  };

  const component =
    htmlFor && !disabled ? { component: FormLabel, htmlFor } : {};

  return (
    <ButtonBase
      {...component}
      id={customId}
      data-testid={dataTestid}
      disabled={disabled}
      className={clsx(
        classes.btn_base,
        colorClasses[buttonType],
        sizeClasses[buttonSize],
        !startIcon && !endIcon && noIconClasses[buttonSize],
        simpleMode && simpleModeClasses[simpleMode],
        className
      )}
      onClick={onClick}
      disableRipple
      style={{ width: fullWidth ? "100%" : "auto" }}
      type={submit ? "submit" : "button"}
    >
      {startIcon && (
        <Flex
          container
          alignItems="center"
          justifyContent="center"
          id={customId}
          className={clsx(classes.icon_base, classes.icon_start, iconClass)}
        >
          {startIcon}
        </Flex>
      )}
      <span className={classes.text_base}>{children}</span>
      {endIcon && (
        <Flex
          container
          alignItems="center"
          justifyContent="center"
          id={customId}
          className={clsx(classes.icon_base, classes.icon_end, iconClass)}
        >
          {endIcon}
        </Flex>
      )}
    </ButtonBase>
  );
};

export default SolidButton;
