import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import { gds_theme, gds_buttons } from "@ui/goono/styles/theme";
import { ButtonSize } from "../SolidButton";

type ButtonStyle = {
  [key: string]: string | number | object;
};

const createButtonStyle = (
  base: ButtonStyle,
  hover: ButtonStyle,
  active: ButtonStyle
): ButtonStyle => ({
  ...base,
  "@media (hover: hover)": {
    "&:hover": hover,
  },
  "&:active": active,
});

const createSizeStyle = (size: ButtonSize) => ({
  ...gds_buttons[`size_${size}`],
  [`&:has($icon_start)`]: {
    paddingRight: gds_theme[`btn_padding_${size}`] + 2,
  },
  [`&:has($icon_end)`]: {
    paddingLeft: gds_theme[`btn_padding_${size}`] + 2,
  },
});

type BreakpointKey = Breakpoint | string;

const createSimpleStyle = (breakpoint: BreakpointKey) => ({
  [breakpoint]: {
    "& $text_base": { display: "none" },
    "& $icon_start,& $icon_end": { margin: 0 },
  },
});

const buttonStyles = {
  primary: createButtonStyle(
    gds_buttons.btn_primary,
    gds_buttons.btn_primary_hover,
    gds_buttons.btn_primary_active
  ),
  primary_line: createButtonStyle(
    gds_buttons.btn_primary_line,
    gds_buttons.btn_primary_line_hover,
    gds_buttons.btn_primary_line_active
  ),
  white: createButtonStyle(
    gds_buttons.btn_white,
    gds_buttons.btn_white_hover,
    gds_buttons.btn_white_active
  ),
  gray: createButtonStyle(
    gds_buttons.btn_gray,
    gds_buttons.btn_gray_hover,
    gds_buttons.btn_gray_active
  ),
  gray_line: createButtonStyle(
    gds_buttons.btn_gray_line,
    gds_buttons.btn_gray_line_hover,
    gds_buttons.btn_gray_line_active
  ),
  cool_gray: createButtonStyle(
    gds_buttons.btn_cool,
    gds_buttons.btn_cool_hover,
    gds_buttons.btn_cool_active
  ),
  cool_gray_line: createButtonStyle(
    gds_buttons.btn_cool_line,
    gds_buttons.btn_cool_line_hover,
    gds_buttons.btn_cool_line_active
  ),
  green: createButtonStyle(
    gds_buttons.btn_green,
    gds_buttons.btn_green_hover,
    gds_buttons.btn_green_active
  ),
  danger: createButtonStyle(
    gds_buttons.btn_danger,
    gds_buttons.btn_danger_hover,
    gds_buttons.btn_danger_active
  ),
  navy: createButtonStyle(
    gds_buttons.btn_navy,
    gds_buttons.btn_navy_hover,
    gds_buttons.btn_navy_active
  ),
  success: createButtonStyle(
    gds_buttons.btn_success,
    gds_buttons.btn_success_hover,
    gds_buttons.btn_success_active
  ),
  error: createButtonStyle(
    gds_buttons.btn_error,
    gds_buttons.btn_error_hover,
    gds_buttons.btn_error_active
  ),
};

const useStyles = makeStyles((MuiTheme: Theme) =>
  createStyles({
    btn_base: {
      whiteSpace: "nowrap",
      borderRadius: gds_theme.round_3,
      ...gds_theme.btn_transition,
      ...gds_buttons.btn_base,
      "&:disabled, &.Mui-disabled": {
        ...gds_buttons.btn_disabled,
      },
    },
    btn_xl: createSizeStyle("xl"),
    btn_lg: createSizeStyle("lg"),
    btn_md: createSizeStyle("md"),
    btn_sm: {
      ...createSizeStyle("sm"),
      "& $icon_base": {
        ...gds_theme.btn_icon_sm,
      },
    },
    noIcon_lg: {
      ...gds_theme.btn_padding_lg_noIcon,
    },
    noIcon_md: {
      ...gds_theme.btn_padding_md_noIcon,
    },
    noIcon_sm: {
      ...gds_theme.btn_padding_sm_noIcon,
    },
    text_base: {},
    icon_base: {
      ...gds_theme.btn_icon_lg,
      "& img, & svg": {
        maxWidth: "100%",
        maxHeight: "100%",
        fill: "currentColor",
      },
    },
    icon_start: {
      marginRight: 4,
    },
    icon_end: {
      marginLeft: 4,
    },
    simple_xl: {
      "& $text_base": { display: "none" },
      "& $icon_start,& $icon_end": { margin: 0 },
    },
    simple_lg: createSimpleStyle(MuiTheme.breakpoints.down(gds_theme.break_lg)),
    simple_md: createSimpleStyle(MuiTheme.breakpoints.down(gds_theme.break_md)),
    simple_sm: createSimpleStyle(MuiTheme.breakpoints.down(gds_theme.break_sm)),
    ...buttonStyles,
  })
);

export default useStyles;
