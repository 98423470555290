import React from "react";
import SNSLoginButton from "../../public/login/SNSLoginButton";
import { ExtendedGApiWindow } from "./types/TypesGoogle";
import translate from "@translate";
import { getLoggers } from "@redwit-commons/utils/log";
import { connect, ConnectedProps } from "react-redux";
import { FutureComponent } from "@redwit-react-commons/template/FutureComponent";
import {
  doDiaLogAction,
  DiaLogActionKind,
  DiaLogType,
} from "src/redux/store/reducers/dialog";

const { ERROR } = getLoggers("GoogleLogin");

declare let window: ExtendedGApiWindow;

const mapStateToProps = () => {
  return {};
};

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

type GoogleLoginButtonProps = {
  callback?: (token: string, email: string) => void;
  signup?: boolean;
} & PropsFromRedux;

class GoogleLoginButton extends FutureComponent<GoogleLoginButtonProps> {
  constructor(props: GoogleLoginButtonProps) {
    super(props);
  }

  private clientId =
    "962748495091-kviruka25tfv4aqq0ckau6fpbse9odjv.apps.googleusercontent.com"; // dev
  // "767415015003-068jk0b37f9crjr2qf23l9rv4jmidi1q.apps.googleusercontent.com"; // prd, not verified yet
  private cookiePolicy = "single_host_origin";

  onButtonClick() {
    this.scheduleTask(async () => {
      const params = {
        client_id: this.clientId,
        cookie_policy: this.cookiePolicy,
        fetch_basic_profile: true,
        ux_mode: "popup",
        scope: "profile email",
        access_type: "online",
      };
      if (window.gapi) {
        await window.gapi.load("auth2", async () => {
          const GoogleAuth = window.gapi.auth2.getAuthInstance();
          if (!GoogleAuth) {
            try {
              await window.gapi.auth2.init(params);
              const auth2 = window.gapi.auth2.getAuthInstance();
              if (auth2.isSignedIn.get()) {
                await auth2.signOut();
                // auth2.disconnect()
                const newAuth2 = window.gapi.auth2.getAuthInstance();
                const res = await newAuth2.signIn({ prompt: "select_account" });
                const basicProfile = res.getBasicProfile();
                const authResponse = res.getAuthResponse(true);
                if (this.props.callback !== undefined)
                  return this.props.callback(
                    authResponse.id_token,
                    basicProfile.getEmail()
                  );
              } else {
                const res = await auth2.signIn({ prompt: "select_account" });
                const basicProfile = res.getBasicProfile();
                const authResponse = res.getAuthResponse(true);
                if (this.props.callback !== undefined)
                  return this.props.callback(
                    authResponse.id_token,
                    basicProfile.getEmail()
                  );
              }
            } catch (err: any) {
              ERROR("GoogleLoginButton", "error in google login");
              console.error(err);
              if (err.details)
                doDiaLogAction(this.props.dispatch, {
                  kind: DiaLogActionKind.TRY_OPEN,
                  type: DiaLogType.ERROR,
                  bodyMsg: translate.error.google_secret_tab,
                  confirmBtnMsg: translate.modal.button.confirm,
                });
            }
          } else {
            const auth2 = window.gapi.auth2.getAuthInstance();
            const res = await auth2.signIn({ prompt: "select_account" });
            const basicProfile = res.getBasicProfile();
            const authResponse = res.getAuthResponse(true);
            if (this.props.callback !== undefined)
              return this.props.callback(
                authResponse.id_token,
                basicProfile.getEmail()
              );
          }
        });
      } else {
        doDiaLogAction(this.props.dispatch, {
          kind: DiaLogActionKind.TRY_OPEN,
          type: DiaLogType.ERROR,
          bodyMsg: translate.error.login_fail,
          confirmBtnMsg: translate.modal.button.confirm,
        });
      }
    });
  }

  render() {
    return (
      <SNSLoginButton
        sns="google"
        onClick={() => {
          this.onButtonClick();
        }}
        signup={this.props.signup}
      />
    );
  }
}

export default GoogleLoginButton;
