import { makeStyles, Theme } from "@material-ui/core";
import { theme, service } from "@theme";

const useStyles = makeStyles((MuiTheme: Theme) => ({
  root: {
    display: "flex",
  },
  dim: {
    ...theme.dim_bg,
  },
  wrapper: {
    position: "relative",
    width: "100%",
    borderRadius: theme.round_4,
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    ...theme.shadow_3,
    [MuiTheme.breakpoints.down(theme.break_sm)]: {
      "&&": {
        maxWidth: "100%",
        height: "unset",
        position: "fixed",
        top: 48,
        bottom: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      },
    },
  },
  small: {
    maxWidth: 280,
    "& $header_container": {
      padding: "16px 20px 12px",
    },
    "& $body_container": {
      padding: "0 30px",
    },
    "& $footer_container": {
      padding: "7.5px 20px",
    },
  },
  large: {
    maxWidth: 840,
    [MuiTheme.breakpoints.down(theme.break_md)]: {
      maxWidth: 560,
    },
  },
  middle: {
    maxWidth: 560,
    height: 560,
    "& $header_container": {
      padding: "16px 32px 12px",
      [MuiTheme.breakpoints.down(theme.break_sm)]: {
        padding: "16px 20px",
      },
    },
    "& $body_container": {
      padding: "0 32px",
      height: "100%",
      [MuiTheme.breakpoints.down(theme.break_sm)]: {
        padding: "0 20px",
      },
    },
    "& $footer_container": {
      padding: "8px 32px",
    },
  },
  header_container: {
    backgroundColor: theme.white,
    padding: "16px 40px 12px",
    borderBottom: `1px solid ${theme.gray_3}`,
    color: theme.gray_9_title,
    gap: 4,
    borderTopRightRadius: theme.round_4,
    borderTopLeftRadius: theme.round_4,
    [MuiTheme.breakpoints.down(theme.break_md)]: {
      padding: "16px 32px 12px",
    },
    [MuiTheme.breakpoints.down(theme.break_sm)]: {
      padding: "16px 20px 12px",
    },
  },
  header_subtitle: {
    color: theme.gray_7_text,
  },
  body_container: {
    background: theme.gray_0,
    overflowY: "auto",
    maxHeight: 560,
    [MuiTheme.breakpoints.down(theme.break_sm)]: {
      flex: "1 1 0",
      maxHeight: "100%",
      height: "100%",
    },
  },
  body_container_no_scrollbar: {
    background: theme.gray_0,
    maxHeight: 560,
    [MuiTheme.breakpoints.down(theme.break_sm)]: {
      flex: "1 1 0",
      maxHeight: "100%",
      height: "100%",
    },
  },
  body_padding: {
    padding: "0 40px",
    [MuiTheme.breakpoints.down(theme.break_md)]: {
      padding: "0 32px",
    },
    [MuiTheme.breakpoints.down(theme.break_sm)]: {
      padding: "0 20px",
    },
  },
  body_container_pdf_download_desktop: {
    padding: `0 32px`,
  },
  full_body: {
    "&&": {
      padding: 0,
    },
  },
  fix_body: {
    height: 418,
  },
  footer_container: {
    padding: "8px 40px",
    gap: 12,
    justifyContent: "flex-end",
    borderTop: `1px solid ${theme.gray_3}`,
    [MuiTheme.breakpoints.down(theme.break_md)]: {
      padding: "8px 32px",
    },
    [MuiTheme.breakpoints.down(theme.break_sm)]: {
      padding: "8px 20px",
    },
  },
  default_icon: {
    color: theme.gray_7_text,
  },
  select_type_root: {
    "& $wrapper": {
      height: 560,
    },
    "& $body_container": {
      padding: 0,
      height: "100%",
      maxHeight: "100%",
      overflow: "hidden",
    },
  },

  desktop_pdf_download_wrapper: {
    width: `100%`,
    display: "flex",
    height: `100%`,
    overflowY: `hidden`,
    backgroundColor: service.primary_50,
    borderTopLeftRadius: theme.round_4,
    borderTopRightRadius: theme.round_4,
  },

  desktop_pdf_download_left_box: {
    width: 560,
    height: `100%`,
    boxShadow: `0 4px 16px 0 rgba(33, 37, 41, 0.2)`,
    overflow: "hidden",
    "& $fix_body": {
      height: 419,
    },
  },
}));
export default useStyles;
