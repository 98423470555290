export function loadScript(props: {
  script_option: { src: string; async?: boolean };
  onload: () => void;
}) {
  const { src, async = true } = props.script_option;

  /** 이미 존재하는 script인지 검사 */
  const existingScript = document.querySelector(`script[src="${src}"]`);

  if (existingScript) {
    props.onload();
  } else {
    const newScriptEl = document.createElement("script");

    newScriptEl.src = src;
    newScriptEl.async = async;

    document.body.appendChild(newScriptEl);

    newScriptEl.onload = props.onload;
  }
}
