import React from "react";
import useSignInScreenStyles from "./styles";
import { Divider } from "@material-ui/core";
import GoonoTypography from "@ui/goono/components/typo/GoonoTypography";
import translate from "@translate";
import { GoonoLoginArgs } from "@goono-react-commons/services/user";
import { InternalErrorKind } from "@redwit-commons/utils/exception2";
import {
  doSnackbarAction,
  SnackbarActionKind,
  SnackbarType,
} from "src/redux/store/reducers/snackbar";
import { TokenErrorType } from "src/redux/store/reducers/token";
import { useLoginMutation } from "@react-query/api/token";
import { useDispatch } from "react-redux";
import SignInScreenSNSLoginForm from "./SNSLoginForm";
import SignInScreenFooter from "./Footer";
import SignInScreenEmailLoginForm from "./EmainLoginForm";
import GoonoBaseGrid from "@utils/templates/GoonoBaseGrid";
import { useMoveServiceDashboard } from "@utils/hooks/service/useMoveServiceDashboard";
import { useQueryClient } from "react-query";

export default function SigninScreen() {
  const queryClient = useQueryClient();
  const loginMutation = useLoginMutation();
  const dispatch = useDispatch();
  const { moveServiceDashboard } = useMoveServiceDashboard();

  const onSignIn = async (args: GoonoLoginArgs) => {
    try {
      await loginMutation.mutateAsync(args);
      queryClient.removeQueries();
      moveServiceDashboard();
    } catch (error) {
      let msg = "";
      const err = error as any;
      if (err.kind !== InternalErrorKind.Abort) return;

      if (err.msg === TokenErrorType.BLOCKED_30_MINS)
        msg = `로그인 실패 횟수를 초과하여 30분간 로그인이 제한되었습니다.`;
      else if (err.msg === TokenErrorType.SEND_VERIFY_MAIL_AGAIN)
        msg = translate.alert.resend_auth_email;
      else if (err.msg === TokenErrorType.EMAIL_NOT_VERIFY)
        msg = translate.error.check_auth_email;
      else if (err.msg === TokenErrorType.PW_INVALID)
        msg = `비밀번호를 ${
          err?.failedCount ?? 1
        }/5회 잘못입력하셨습니다. 5회 오류시 로그인이 제한됩니다.`;
      else if (err.msg === TokenErrorType.NOT_REGISTERED)
        msg = translate.error.unregistered_email;
      else if (err.msg === TokenErrorType.INCORRECT_PLATFORM)
        msg = translate.error.diffrent_platform_account;
      else msg = translate.error.failed_signin;

      doSnackbarAction(dispatch, {
        kind: SnackbarActionKind.TRY_OPEN,
        type: SnackbarType.ALERT,
        msg,
      });
    }
  };

  return (
    <GoonoBaseGrid viewerOption={{ type: "landing_page" }}>
      <GoonoTypography type="h2" style={{ marginBottom: 20 }}>
        {translate.sign_in.title}
      </GoonoTypography>
      <SignInScreenEmailLoginForm onSignIn={onSignIn} />
      <SignInScreenFooter />
      <LoginMethodDivider />
      <SignInScreenSNSLoginForm onSignIn={onSignIn} />
    </GoonoBaseGrid>
  );
}

function LoginMethodDivider() {
  const classes = useSignInScreenStyles();
  return (
    <div className={classes.method_divider_container}>
      <Divider className={classes.method_divider_line} />
      <GoonoTypography type="b2_m">SNS LOGIN</GoonoTypography>
      <Divider className={classes.method_divider_line} />
    </div>
  );
}
