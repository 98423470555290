import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../store/reducers";
import { ReduxStateComponent3 } from "@redwit-react-commons/template/ReduxStateComponent3";
import {
  NDACustomScreenActionKind,
  NDACustomScreenAction,
  NDACustomScreenStateMachine,
  NDACustomScreenStateMachineType,
  NDACustomScreenState,
} from "../store/reducers/ndaCustomScreen";

const mapStateToProps = (state: RootState) => {
  return {
    reduxState: state.ndaCustomScreen,
  };
};

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type NDACustomScreenContainerProps = PropsFromRedux & {
  stateMachine: NDACustomScreenStateMachineType;
};

export const handleNDACustomScreenAction = (
  prevState: NDACustomScreenState,
  action: NDACustomScreenAction
): NDACustomScreenState => {
  switch (action.kind) {
    case NDACustomScreenActionKind.TRY_UPDATE_STATE: {
      return {
        ...prevState,
        ...action.newState,
      };
    }
  }
};

class NDACustomScreenContainer extends ReduxStateComponent3<NDACustomScreenContainerProps> {
  static defaultProps = { stateMachine: NDACustomScreenStateMachine };
  constructor(props: NDACustomScreenContainerProps) {
    super(props);
  }

  protected onAction = handleNDACustomScreenAction;
}

export default connector(NDACustomScreenContainer);
