import React from "react";
import {
  Box,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
import BaseModal, { ModalSize } from "@utils/templates/BaseModal";
import { useDispatch } from "react-redux";
import {
  NdaSignListModalProps,
  ModalActionKind,
  doModalActionAsync,
} from "src/redux/store/reducers/modal";
import translate from "@translate";
import useNDASignListModalStyles from "./styles/NDASignListModal";

export default function NDASignListModal(_props: NdaSignListModalProps) {
  const classes = useNDASignListModalStyles();
  const dispatch = useDispatch();

  const closeModal = async () => {
    await doModalActionAsync(dispatch, {
      kind: ModalActionKind.TRY_CLOSE,
    });
  };

  const renderBody = () => (
    <Box className={classes.body_container}>
      <Box>데이터룸 서명 내역 Table</Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>NDA 문서명</TableCell>
            <TableCell>워크스페이스</TableCell>
            <TableCell>서명상태</TableCell>
            <TableCell>서명일자</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>NDA 파일 명</TableCell>
            <TableCell>워크스페이스 명</TableCell>
            <TableCell>서명 대기중</TableCell>
            <TableCell>yyyy.mm.dd</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
  return (
    <BaseModal
      open
      size={ModalSize.Middle}
      title={"데이터룸 NDA 서명 내역"}
      confirmButtonMsg={translate.button.confirm}
      body={renderBody()}
      onConfirm={closeModal}
    />
  );
}
