export default {
  ko: {
    note: {
      title: "연구노트",
      comment: {
        just_now: "방금 전",
        minutes_ago: "분 전",
        hours_ago: "시간 전",
        comment_by: (name: string) => `${name}님이 고정한 댓글`,
        option: "코멘트 옵션",
        comment_pin: "코멘트 고정",
        comment_pin_clear: "코멘트 고정 해제",
        delete: "삭제",
      },
      input: {
        placeholder: "연구노트 이름으로 검색해 보세요.",
      },
      overview: {
        title_2: "연구노트 활동",
        graph_6: "가장 많이 파일추가한 연구노트",
        tooltip_2_1: "클릭 시 아래 연구파일 현황에서 자동 검색됩니다.",
        tooltip_2_2: "더블 클릭 시 노트 안 폴더를 확인할 수 있습니다.",
        graph_7: "노트 당 평균 연구파일 추가 개수",
        graph_8: "연구파일 내 연구노트 해시태그 순위",
        graph_9: "노트 별 연구파일 현황",
      },
      note_item: {
        owner: "소유권한",
        my_auth: "나의 권한",
        members: "워크스페이스 멤버",
        type: "과제 유형",
        research_period: "과제 기간",
        graph: "워크스페이스 멤버 별 업로드 현황",
        total_files: "총 업로드 연구파일 수",
        shared_note: "공유 노트",
        private_note: "개인 노트",
      },
      auth: {
        empty_auth: "인증 받은 연구파일이 아직 없어요.",
        empty_file: "사용자가 업데이트 한 연구파일이 없습니다",
        guide_add_file: "연구파일 추가 버튼을 눌러 파일을 추가해보세요!",
        guide_google_drive: "구글 드라이브에서 추가도 가능해요.",
        block_num: "번째 블록",
        note: "저장 노트",
        sign: "전자 서명",
        hash: "해시",
        auth: "인증",
        note_to_save: "저장노트",
      },
      sidebar: {
        gnb: {
          note: "연구노트",
          doc: "데이터룸",
        },
        upload: {
          file: "파일 추가",
          google_drive: "구글 드라이브에서 추가",
          add_file: "연구파일 추가",
          tag: "태그 추가하기",
          no_tag: "태그가 없습니다.",
        },
        workspace: {
          setting: "워크스페이스 설정",
          create: "새 워크스페이스 생성",
        },
        home: "HOME",
        setting: "데이터룸 설정",
        dashboard: "대시보드",
        note: "연구노트 목록",
        block: "블록체인 인증현황",
        service: "깃허브 연동",
        more_about_goono: "더 많은 구노 이야기가 궁금하다면?",
        story: "스토리",
        notice: "공지사항",
        faq: "자주 묻는 질문",
        manage_goono: "관리자 메뉴",
        bookmark_notes: "즐겨찾는 연구노트",
        manage_user: "사용자 관리",
        admin_dashboard: "사용자 관리",
        mypage: "마이페이지",
        feedback: "구노 피드백 남기기",
        patent_management: "특허 관리",
        more: "더 많은 구노 이야기가 궁금하다면?",
      },
      delete: {
        title: "연구노트 삭제",
        body_title: "연구노트를 삭제할까요?",
        msg: (file: number) =>
          `삭제하시면 작성된 연구파일 ${file}개 복구가 불가능합니다.\n정말 삭제하시겠어요?`,
        msg_highlight: (file: number) => `연구파일 ${file}개 복구가 불가능`,
        confirm: "연구노트 삭제",
      },
      workspace_plan: {
        enterprise: {
          name: "Enterprise",
          guide_value:
            "기관 및 연구실을 위한 \n 할인된 견적서 를 보내드립니다.",
          guide_highlight: "할인된 견적서",
          func1: "관리자 계정 및 관리 페이지 제공",
          func2: "Github, 구글 드라이브 연동",
          func3: "OCR 검색, HASHTAG 검색",
          func4: "연구노트 생성 무제한",
          func5: "연구노트 시점인증 무제한",
          func6: "연구노트 공유 및 공동 작성 가능",
          func7: "연구노트 PDF생성 및 다운로드 가능",
        },
        enterprise_pro: {
          name: "Enterprise Pro",
          guide_value:
            "연구노트와 데이터룸을 \n 결합하여 이용 시 할인 적용해드립니다.",
          guide_highlight: "할인 적용",
          card_title:
            "스트롱박스와 구노를 결합으로 \n 구매하여 할인을 받아보세요!",
          card_title_highlight: "스트롱박스와 구노를",
          go_service: "기능 보러가기",
        },
        education: {
          name: "Education",
          guide_value:
            "기관 및 연구실을 위한 \n 할인된 견적서 를 보내드립니다.",
          guide_highlight: "할인된 견적서",
          func1: "Enterprise 와 동일 기능 제공",
          func2: "코어관리자 추가 가능",
        },
        data_room_business: {
          name: "Business",
          guide_value: "최소 10인 이상의 \n 기관을 위한 플랜입니다.",
        },
        data_room_individual: {
          name: "Individual",
          price_title: "월 8,000원",
          price_sub_title: "/ 1년 96,000원",
          guide_value: "연간 결제만 가능합니다.",
          guide_highlight: "연간 결제",
          contactBtnMsg: "체험하기",
          start: "결제하기",
        },

        price: "가격협의",
        contact: "견적문의",
        consulting: "상담이 필요하신가요?",
        free: {
          title: "Free",
          price: "0원",
          color_text: "무료",
          text: "로 구노를 사용해 볼 수 있어요.",
          func1: "연구노트 시점인증 20회",
          func2: "연구노트 최대 3개 생성(공유하기, 공유 받기 불가능)",
          func3: "연구노트 PDF 다운로드 시 워터마크 생성",
          confirm: "연구노트 삭제",
        },
        team: {
          title: "Enterprise",
          price: "협의",
          color_text1: "맞춤형 플랜으로",
          color_text2: "견적서",
          highlight_text: "연구비 집행이 가능합니다!",
          text1: "기업 및 팀에 맞는",
          text2: "를 보내드립니다.",
          func1: "관리자 계정 및 관리 페이지 제공",
          func2: "Github, 구글 드라이브 연동",
          func3: "OCR 검색, HASHTAG 검색",
          func4: "연구노트 생성 무제한",
          func5: "연구노트 시점인증 무제한",
          func6: "연구노트 공유 및 공동 작성 가능",
          func7: "연구노트 PDF생성 및 다운로드 가능",
        },
        premium: {
          title: "Education",
          price: "협의",
          vat: "VAT 별도",
          color_text: "할인된 견적서",
          text1: "기관 및 연구실을 위한",
          text2: "를 보내드립니다.",
          func1: "Enterprise 와 동일 기능 제공",
          func2: "코어관리자 추가 가능",
        },
      },
    },
  },
  en: {
    note: {
      title: "Research notes",
      comment: {
        just_now: "Just Now",
        minutes_ago: "Minutes Ago",
        hours_ago: "Hours Ago",
        comment_by: (name: string) => `Pinned comments by ${name}`,
        option: "Comment Options",
        comment_pin: "Pinned",
        comment_pin_clear: "Unpinned",
        delete: "Delete",
      },
      input: {
        placeholder: "Search with name of research notes.",
      },
      overview: {
        title_2: "Note Summary",
        graph_6: "Most Activate Notes",
        tooltip_2_1: "Click to apply search below.",
        tooltip_2_2: "Double-click to view research files.",
        graph_7: "Average Number of Files per Note",
        graph_8: "Frequently Used Hashtags",
        graph_9: "File Overview",
      },
      note_item: {
        owner: "Owner",
        my_auth: "My Role",
        members: "Members",
        type: "Note Type",
        research_period: "Research Period",
        graph: "Number of Files Uploaded",
        total_files: "Total Number of files",
        shared_note: "Shared Note",
        private_note: "Private Note",
      },
      auth: {
        empty_auth: "Don't have any certified research files.",
        empty_file: "User don't have any certified research file.",
        guide_add_file: "Add files by clicking on 'Add Files' button!",
        guide_google_drive: "You can also add through Google Drive.",
        block_num: " Blocks",
        note: "Note",
        sign: "Signature",
        hash: "Hash",
        auth: "Certification",
        note_to_save: "Note to Save",
      },
      sidebar: {
        gnb: {
          note: "Research Notes",
          doc: "Data Room",
        },
        upload: {
          file: "Add research files",
          google_drive: "From Google Drive",
          add_file: "Add research files",
          tag: "Add tags",
          no_tag: "No tags",
        },
        workspace: {
          setting: "Settings",
          create: "Create workspace",
        },
        home: "HOME",
        setting: "데이터룸 설정",
        dashboard: "Dashboard",
        note: "Research notes",
        block: "Blockchain",
        service: "Github Integration",
        more_about_goono: "If you wonder about more GOONO?",
        story: "Story",
        notice: "Notice",
        faq: "FAQ",
        manage_goono: "GOONO management",
        bookmark_notes: "Bookmarks",
        manage_user: "User management",
        admin_dashboard: "Admin dashboard",
        mypage: "My page",
        feedback: "Feedback to GOONO",
        patent_management: "Patent Management",
        more: "If you wonder about more GOONO?",
      },
      delete: {
        title: "Delete research note",
        body_title: "Are you sure to delete research note?",
        msg: (file: number) =>
          `If you delete it, you cannot restore ${file} created research files.\nAre you sure to delete?`,
        msg_highlight: (file: number) =>
          `cannot restore ${file} created research files.`,
        confirm: "Delete",
      },
      workspace_plan: {
        enterprise: {
          name: "Enterprise",
          guide_value:
            "We will send a discounted quote \n for organizations and research labs.",
          guide_highlight: "a discounted quote",
          func1: "Admin account and management page",
          func2: "Integrating with GitHub, Google Drive",
          func3: "OCR, hashtag search",
          func4: "Unlimited research note",
          func5: "Unlimited note authentication",
          func6: "Sharing and collaborative writing notes",
          func7: "Create PDF and download for notes",
        },
        enterprise_pro: {
          name: "Enterprise Pro",
          guide_value:
            "연구노트와 데이터룸을 \n 결합하여 이용 시 할인 적용해드립니다.",
          guide_highlight: "할인 적용",
          card_title:
            "Get a bundle discount by siging up \n for Goono and Strong Box!",
          card_title_highlight: "Goono and Strong Box!",
          go_service: "Learn about features",
        },
        education: {
          name: "Education",
          guide_value:
            "We will send a discounted quote \n for organizations and research labs.",
          guide_highlight: "a discounted quote",
          func1: "Same features as Enterprise",
          func2: "Additional core administrator features.",
        },
        data_room_business: {
          name: "Business",
          guide_value: "For a team of 10+ memebers",
        },
        data_room_individual: {
          name: "Individual",
          price_title: "￦8,000 monthly",
          price_sub_title: "/ ￦96,000 annually",
          guide_value: "Currenty only accepting annual billing",
          guide_highlight: "only accepting annual",
          contactBtnMsg: "Start",
          start: "Complete Payment",
        },
        price: "Custom",
        contact: "Contact sales",
        consulting: "Contact GOONO",
        free: {
          title: "Free",
          price: "0 Won",
          color_text: "Freely,",
          text: "You can use GOONO.",
          func1: "Certification up to 20 times",
          func2: "Maximum 3 research notes(cannot sharing)",
          func3: "Watermark when extracting as PDF",
          confirm: "Delete",
        },
        team: {
          title: "Enterprise",
          price: "Custom Pricing",
          color_text1: "customized solution",
          color_text2: "with discount.",
          highlight_text: "Research fund use available!",
          text1: "Each team will receive",
          text2: "",
          func1: "Admin account and management page",
          func2: "Integrating with GitHub, Google Drive",
          func3: "OCR, hashtag search",
          func4: "Unlimited research note",
          func5: "Unlimited note authentication",
          func6: "Sharing and collaborative writing notes",
          func7: "Create PDF and download for notes",
        },
        premium: {
          title: "Premium",
          price: "120,000 Won / month",
          vat: "VAT excluded",
          color_text: "without limit ",
          text1: "You can use all functions",
          text2: " such as interlocking with Github.",
          func1: "Interlocking with Github, Google Drive",
          func2: "OCR search available",
        },
      },
    },
  },
};
