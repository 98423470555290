import React, { ReactNode } from "react";
import { Box, CircularProgress, Modal, Paper } from "@material-ui/core";
import Flex from "@ui/goono/components/layouts/Flex";
import GoonoTypography from "@ui/goono/components/typo/GoonoTypography";
import SolidButton from "@ui/goono/components/buttons/SolidButton";
import ArrowToolTip from "@ui/goono/components/Tooltips/ArrowToolTip";
import ClassRoundedIcon from "@material-ui/icons/ClassRounded";
import useStyles from "./styles/BaseModal";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { RootState } from "src/redux/store/reducers";
import { ModalType } from "src/redux/store/reducers/modal";
import { theme } from "@theme";

export enum ModalSize {
  Small,
  Middle,
  Large,
}
export enum ModalUIType {
  Default,
  Select,
}

type BaseModalHeaderProps = {
  title?: string;
  subTitle?: string;
  subTitleIcon?: ReactNode;
  headerElem?: ReactNode;
  isDesktopPDFDownloadModal?: boolean;
};
function BaseModalHeader({
  title,
  subTitle,
  subTitleIcon,
  headerElem,
}: BaseModalHeaderProps) {
  const classes = useStyles();
  const modal = useSelector((state: RootState) => state.modal);

  const isDownloadModal =
    modal.state.openedModalList.find(
      (openedModal) => openedModal.type === ModalType.DOWNLOAD_PDF
    ) !== undefined;

  return (
    <Flex
      container
      flexDirection="column"
      className={classes.header_container}
      style={isDownloadModal ? { padding: `16px 20px 12px` } : {}}
    >
      {title && <GoonoTypography type="h5">{title}</GoonoTypography>}
      <Flex
        container
        alignItems="center"
        gap="4px"
        className={classes.header_subtitle}
      >
        {subTitle && (
          <>
            {subTitleIcon}
            <ArrowToolTip title={subTitle} viewOverflow={true}>
              <div style={{ overflow: "hidden" }}>
                <GoonoTypography type="b4_m" noWrap>
                  {subTitle}
                </GoonoTypography>
              </div>
            </ArrowToolTip>
          </>
        )}
      </Flex>
      {headerElem}
    </Flex>
  );
}
type BaseModalFooterProps = {
  confirmButtonType: "primary" | "danger";
  disableConfirm?: boolean;
  disableCancel?: boolean;
  cancelButtonMsg?: string;
  confirmButtonMsg: string;
  confirmButtonId?: string;
  onCancel?: (() => void) | (() => Promise<void>);
  onConfirm?: (() => void) | (() => Promise<void>);
};
function BaseModalFooter({
  confirmButtonType,
  disableConfirm,
  disableCancel,
  cancelButtonMsg,
  confirmButtonMsg,
  confirmButtonId,
  onCancel,
  onConfirm,
}: BaseModalFooterProps) {
  const classes = useStyles();
  return (
    <Flex container className={classes.footer_container}>
      {onCancel && (
        <SolidButton
          buttonType="gray"
          onClick={onCancel}
          disabled={disableCancel}
        >
          {cancelButtonMsg}
        </SolidButton>
      )}

      {onConfirm && (
        <SolidButton
          customId={confirmButtonId}
          buttonType={confirmButtonType}
          onClick={onConfirm}
          disabled={disableConfirm}
        >
          {confirmButtonMsg}
        </SolidButton>
      )}
    </Flex>
  );
}

type BaseModalProps = {
  open: boolean;
  body?: React.ReactNode;
  pdfDownloadThumbUI?: React.ReactNode;
  fullWidth?: boolean;
  size: ModalSize;
  fixHeight?: boolean;
  wrapperClass?: string;
  uiType: ModalUIType;
  loading?: boolean;
  containerId?: string;
  confirmButtonId?: string;
  alreadyHasScrollbar?: boolean;
} & BaseModalHeaderProps &
  BaseModalFooterProps;

export default function BaseModal({
  title,
  subTitle,
  subTitleIcon,
  confirmButtonType,
  disableConfirm,
  disableCancel,
  cancelButtonMsg,
  confirmButtonMsg,
  onCancel,
  onConfirm,
  open,
  body,
  fullWidth,
  headerElem,
  size,
  fixHeight,
  wrapperClass,
  uiType,
  pdfDownloadThumbUI,
  loading,
  containerId,
  confirmButtonId,
  alreadyHasScrollbar,
}: BaseModalProps) {
  const classes = useStyles();
  const modal = useSelector((state: RootState) => state.modal);

  const isDesktopPDFDownloadModal =
    modal.state.openedModalList.find(
      (openedModal) => openedModal.type === ModalType.DOWNLOAD_PDF
    ) !== undefined && window.innerWidth >= theme.break_md;

  const bodyContainerClassName = clsx(
    alreadyHasScrollbar
      ? classes.body_container_no_scrollbar
      : classes.body_container,
    fullWidth === true ? classes.full_body : classes.body_padding,
    fixHeight && classes.fix_body
  );

  const getModalSize = () => {
    switch (size) {
      case ModalSize.Small:
        return [classes.small];
      case ModalSize.Large:
        return [classes.large];
      case ModalSize.Middle:
        return [classes.middle];
      default:
        return [classes.large];
    }
  };

  const getModalType = () => {
    switch (uiType) {
      case ModalUIType.Default:
        return [];
      case ModalUIType.Select:
        return [classes.select_type_root];
      default:
        return [];
    }
  };

  const renderHeader = () => {
    return (
      <BaseModalHeader
        title={title}
        subTitle={subTitle}
        headerElem={headerElem}
        subTitleIcon={
          subTitleIcon ? (
            subTitleIcon
          ) : (
            <ClassRoundedIcon className={classes.default_icon} />
          )
        }
      />
    );
  };

  const renderPDFDownloadDesktopBody = () => {
    return (
      <div className={classes.desktop_pdf_download_wrapper}>
        <div className={classes.desktop_pdf_download_left_box}>
          {renderHeader()}
          <Box className={bodyContainerClassName}>{body}</Box>
        </div>
        {pdfDownloadThumbUI}
      </div>
    );
  };

  return (
    <Modal
      open={open}
      className={clsx(classes.root, ...getModalType())}
      BackdropProps={{ className: classes.dim }}
    >
      {loading ? (
        <Flex
          container
          fullWidth
          justifyContent={"center"}
          alignItems={"center"}
        >
          <CircularProgress size={50} />
        </Flex>
      ) : (
        <Paper
          id={containerId}
          className={clsx(classes.wrapper, ...getModalSize(), wrapperClass)}
        >
          {isDesktopPDFDownloadModal ? (
            renderPDFDownloadDesktopBody()
          ) : (
            <>
              {renderHeader()}
              <Box className={bodyContainerClassName}>{body}</Box>
            </>
          )}
          <BaseModalFooter
            confirmButtonId={confirmButtonId}
            confirmButtonType={confirmButtonType}
            disableConfirm={disableConfirm}
            disableCancel={disableCancel}
            cancelButtonMsg={cancelButtonMsg}
            confirmButtonMsg={confirmButtonMsg}
            onCancel={onCancel}
            onConfirm={onConfirm}
          />
        </Paper>
      )}
    </Modal>
  );
}

BaseModal.defaultProps = {
  confirmButtonType: "primary",
  size: ModalSize.Large,
  uiType: ModalUIType.Default,
};
