import {
  doTokenActionAsync,
  resetToken,
} from "../../redux/store/reducers/token";
import {
  ILoginWithEmail,
  IRegister,
  IRegisterWithEmail,
  IUpdateLoginUserPW,
  IUpdatePW,
} from "./../../goono-commons/api/request/user";
import {
  goonoAuthenticationPW,
  goonoEmailCheckRegister,
  goonoLogin,
  GoonoLoginArgs,
  goonoLoginType,
  goonoProfile,
  goonoProfileUpdate,
  goonoRegister,
  GoonoRegisterArgs,
  goonoUpdatePW,
  goonoUpdateLoginUserPW,
} from "@goono-react-commons/services/user";
import { RootState } from "../../redux/store/reducers/index";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";
import { mkErr, InternalErrorKind } from "@redwit-commons/utils/exception2";
import {
  TokenActionKind,
  TokenErrorType,
  TokenStateStatus,
} from "src/redux/store/reducers/token";
import { useDispatch, useSelector } from "react-redux";
import { UserProfile } from "@goono-commons/api/object/user";
import {
  emailLoginErrorType,
  snsLoginErrorType,
} from "@goono-commons/api/response/user";
import {
  getIPFSUrlDecrypted,
  uploadFileIPFSWithEncryption,
} from "@goono-react-commons/services/ipfs";
import { ScreenURL } from "src/routes/route_list";
import {
  WorkspaceActionKind,
  doWorkspaceAction,
} from "src/redux/store/reducers/workspace";
import { delay } from "@redwit-commons/utils/function";
import { WorkspaceQueryKey } from "@react-query/key/workspace";

/**
 * @description
 * 회원가입 로직 설명
 *
 * 회원가입 종류 : 이메일 가입 / SNS 간편 가입 ( 카카오, 구글, 애플 )
 *
 * 이메일로 가입 시
 * useRegisterWithEmailMutation -> 메일함에서 인증 메일 내 링크 클릭 -> 가입 완료 -> 로그인 진행
 * SignUpScreen -> 메일함 -> EmailVerificationSuccessScreen -> SignInScreen
 *
 * SNS 간편 가입 시
 * TRY_SAVE_SNS_SIGN_UP_INFO -> useRegisterWithSNSMutation -> 가입과 동시에 로그인 완료
 * SignUpScreen -> RegisterSNSScreen -> Auth 화면
 */

export enum TokenQueryKey {}

export type UseUpdateProfileMutateProps = {
  new_profile?: File;
  new_user_sign?: string;
} & Partial<UserProfile>;

export type UseRegisterEmailMutateProps = {
  args: ILoginWithEmail;
  name: string;
  marketing_term: boolean;
};

export type UseUpdateProfileMutation = UseMutationResult<
  void,
  unknown,
  UseUpdateProfileMutateProps,
  unknown
>;

export const useCheckAleadyRegisteredMutation = () => {
  return useMutation(async (props: { email: string }) => {
    const emailCheckRes = await goonoEmailCheckRegister({
      email: props.email,
    });
    const is_available_email = emailCheckRes.response.available;
    if (is_available_email === true) return;
    else
      throw mkErr({
        kind: InternalErrorKind.Abort,
        loc: "check email",
        msg: TokenErrorType.ALREADY_REGISTERED,
      });
  });
};

export const useUpdateUserProfileMutation = () => {
  const tokenState = useSelector((state: RootState) => state.token).state;
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation(async (props: UseUpdateProfileMutateProps) => {
    if (tokenState.status !== TokenStateStatus.SUCCESS) return;

    const tokenStateSuccess = tokenState;
    const token = tokenStateSuccess.token;
    const profile_cid = tokenStateSuccess.profile_cid;
    const user_sign_cid = tokenStateSuccess.user_sign_cid;
    const user_sign_extension = tokenStateSuccess.user_sign_extension;

    let new_profile_file = props.new_profile;
    let CipherId = tokenStateSuccess.CipherId;
    let new_profile_extension: string | undefined = undefined;
    let new_user_sign_extension: string | undefined = undefined;
    let new_user_sign_file: File | undefined = undefined;
    let updateArgs = { ...props };

    async function getPreviousProfile(prev_profile_extension: string) {
      const profile_url = getIPFSUrlDecrypted(
        token,
        profile_cid,
        prev_profile_extension,
        tokenStateSuccess.CipherId
      );

      const profile_response = await fetch(profile_url.uri, profile_url.init);
      const blob = await profile_response.blob();
      return new File([blob], `profile.${prev_profile_extension}`);
    }

    async function getPreviousSign(sign_extension: string) {
      const url = getIPFSUrlDecrypted(
        token,
        user_sign_cid as string,
        sign_extension,
        tokenStateSuccess.CipherId
      );

      const res = await fetch(url.uri, url.init);
      const blob = await res.blob();
      return new File([blob], `user_sign.${sign_extension}`);
    }

    async function getNewSignFile(sign_extension: string) {
      const res = await fetch(props.new_user_sign as string);
      const blob = await res.blob();
      return new File([blob], `user_sign.${sign_extension}`);
    }

    async function encryptProfileInfo() {
      // 기존 프로필 데이터가 있고, 프로필 변경을 하지 않는 경우 기존 프로필 암호화
      if (new_profile_file === undefined && profile_cid !== undefined) {
        new_profile_extension = tokenStateSuccess.profile_extension ?? "png";
        new_profile_file = await getPreviousProfile(new_profile_extension);
      }

      // 새로운 확장자 할당
      if (new_profile_file !== undefined)
        new_profile_extension = (
          new_profile_file.name.split(".").slice(-1)[0] ?? "unknown"
        ).toLowerCase();

      // 기존 데이터가 존재하고, 사인을 변경하지 않는 경우 기존 사인 암호화
      if (
        props.new_user_sign === undefined &&
        user_sign_cid !== undefined &&
        user_sign_extension !== undefined
      ) {
        new_user_sign_extension = user_sign_extension ?? "png";
        new_user_sign_file = await getPreviousSign(new_user_sign_extension);
      }

      // 사인을 변경하는 경우
      if (props.new_user_sign !== undefined) {
        new_user_sign_extension = "png";
        new_user_sign_file = await getNewSignFile(new_user_sign_extension);
      }
    }

    async function getEncryptProfileInfo() {
      const res = await uploadFileIPFSWithEncryption(
        token,
        new_profile_file as File,
        new_profile_extension as string,
        CipherId
      );

      CipherId = res.CipherId;
      updateArgs = {
        ...updateArgs,
        CipherId,
        profile_cid: res.cid,
        profile_extension: res.extension,
        new_profile: undefined,
      };
    }

    async function getEncryptSignInfo() {
      const res = await uploadFileIPFSWithEncryption(
        token,
        new_user_sign_file as File,
        new_user_sign_extension as string,
        CipherId
      );

      updateArgs = {
        ...updateArgs,
        new_user_sign: undefined,
        user_sign_cid: res.cid,
        user_sign_extension: res.extension,
        CipherId: res.CipherId,
      };
    }

    if (props.new_profile !== undefined || props.new_user_sign !== undefined)
      await encryptProfileInfo();

    if (new_profile_file && new_profile_extension)
      await getEncryptProfileInfo();

    if (new_user_sign_file && new_user_sign_extension)
      await getEncryptSignInfo();

    const newProfileRet = await goonoProfileUpdate(token, updateArgs);

    await doTokenActionAsync(dispatch, {
      kind: TokenActionKind.TRY_UPDATE_PROFILE_INFO,
      userInfo: newProfileRet.response,
    });

    await queryClient.invalidateQueries([
      WorkspaceQueryKey.getJoinedWorkspaceList,
    ]);
  });
};

export const useLoginMutation = () => {
  const location = "useLoginMutation";
  const dispatch = useDispatch();

  return useMutation(async (props: GoonoLoginArgs) => {
    console.log("new login query");

    function throwCommonLoginError() {
      let msg = "";
      switch (ret.errorMessage) {
        case emailLoginErrorType.BLOCKED_30_MINS:
          msg = TokenErrorType.BLOCKED_30_MINS;
          break;
        case emailLoginErrorType.NOT_VERIFIED:
          msg = TokenErrorType.EMAIL_NOT_VERIFY;
          break;
        case emailLoginErrorType.INVALID_PASSWORD:
          msg = TokenErrorType.PW_INVALID;
          break;
        case emailLoginErrorType.SEND_MAIL_AGAIN:
          msg = TokenErrorType.SEND_VERIFY_MAIL_AGAIN;
          break;
        case snsLoginErrorType.INCORRECT_PLATFORM: {
          msg = TokenErrorType.INCORRECT_PLATFORM;
          break;
        }
      }
      throw mkErr({
        kind: InternalErrorKind.Abort,
        loc: location,
        msg,
        failedCount: ret.failedCount,
      });
    }

    async function throwUnRegisterLoginError() {
      let msg = "";
      if (props.type === goonoLoginType.EMAIL) {
        const check = await goonoEmailCheckRegister({
          email: props.args.email,
        });
        if (
          check.response.available === false &&
          check.response.platform !== undefined &&
          check.response.platform !== "email"
        )
          msg = TokenErrorType.INCORRECT_PLATFORM;
      }
      if (msg === "") msg = TokenErrorType.NOT_REGISTERED;

      throw mkErr({
        kind: InternalErrorKind.Abort,
        loc: location,
        msg,
      });
    }

    const ret = await goonoLogin(props);
    if (ret.needRegister) await throwUnRegisterLoginError();
    else if (ret.errorMessage !== undefined) throwCommonLoginError();
    else {
      const infoRet = await goonoProfile(ret.token);
      await doTokenActionAsync(dispatch, {
        kind: TokenActionKind.TRY_SAVE_USER_INFO,
        token: ret.token,
        ...infoRet.response,
      });
    }
  });
};

export const useSendUpdatePasswordEmailMutation = () => {
  return useMutation(async (props: { email: string }) => {
    const check = await goonoEmailCheckRegister({
      email: props.email,
    });

    const isAleadyRegisted = check.response.available === false;

    const isNotEmailAccount =
      check.response.platform !== undefined &&
      check.response.platform !== "email";

    if (isAleadyRegisted && isNotEmailAccount)
      throw mkErr({
        kind: InternalErrorKind.Abort,
        loc: "useSendUpdatePasswordEmailMutation",
        msg: TokenErrorType.INCORRECT_PLATFORM,
      });

    await goonoAuthenticationPW({ email: props.email });
  });
};

export const useUpdatePasswordMutation = () => {
  const dispatch = useDispatch();
  const tokenState = useSelector((state: RootState) => state.token).state;

  // token status가 READY_UPDATE_PW여야 함
  const pwToken =
    tokenState.status === TokenStateStatus.READY_UPDATE_PW
      ? tokenState.pwToken
      : "";

  return useMutation(async (props: { newPassword: string }) => {
    if (pwToken === "") return;

    const args: IUpdatePW = {
      pwToken,
      newPW: props.newPassword,
    };

    const ret = await goonoUpdatePW(args);

    if (ret.response.updated === false)
      throw mkErr({
        kind: InternalErrorKind.Abort,
        loc: TokenActionKind.TRY_UPDATE_PW,
        msg: ret.response.errMSG ? ret.response.errMSG : "update pw error",
      });

    await doTokenActionAsync(dispatch, {
      kind: TokenActionKind.TRY_UPDATE_PW,
    });
  });
};

export const useUpdateLoginedUserPasswordMutation = () => {
  const tokenState = useSelector((state: RootState) => state.token).state;
  const token =
    tokenState.status === TokenStateStatus.SUCCESS
      ? tokenState.token
      : "unknown";

  return useMutation(
    async (props: { prevPassword: string; newPassword: string }) => {
      const args: IUpdateLoginUserPW = {
        prevPW: props.prevPassword,
        newPW: props.newPassword,
      };

      const ret = await goonoUpdateLoginUserPW(token, args);
      return ret.response;
    }
  );
};

export const useLogoutMutation = () => {
  const dispatch = useDispatch();

  return useMutation(async (props: { preventReplaceLocation?: boolean }) => {
    resetToken(dispatch);

    doWorkspaceAction(dispatch, {
      kind: WorkspaceActionKind.TRY_INIT_WORKSPACE,
    });

    /** wait for update persist */
    await delay(100);

    if (props.preventReplaceLocation !== true) {
      window.location.replace(ScreenURL.SIGN_IN);
    }

    return;
  });
};

export const useRegisterWithEmailMutation = () => {
  return useMutation(async (props: UseRegisterEmailMutateProps) => {
    console.log("new register mutation");

    const defaultInfo = {
      profile_version: "0.0.1",
      name: props.name,
      gender: "not-to-disclose",
      school: "-",
      department: "-",
      profile_cid: "QmTL3JvcBvLd8X3PWQdPye3ZwUVEtFKWcNGGULYwFgVQNa",
      profile_extension: "png",
      marketing_term: props.marketing_term,
      user_sign_cid: undefined,
      user_sign_extension: undefined,
    };

    const RegisterArgs: GoonoRegisterArgs = {
      type: goonoLoginType.EMAIL,
      args: { ...props.args, ...defaultInfo } as IRegisterWithEmail,
    };

    await goonoRegister(RegisterArgs);
  });
};

export const useRegisterWithSNSMutation = () => {
  const dispatch = useDispatch();
  const tokenState = useSelector((state: RootState) => state.token).state;

  return useMutation(
    async (props: { name: string; marketing_term: boolean }) => {
      console.log("new sns signup");
      if (tokenState.status !== TokenStateStatus.SUCCESS_SNS_SIGN_UP) return;

      const defaultInfo = {
        profile_cid: "QmTL3JvcBvLd8X3PWQdPye3ZwUVEtFKWcNGGULYwFgVQNa",
        profile_extension: "png",
        name: props.name,
        school: "-",
        department: "-",
        user_sign_cid: undefined,
        user_sign_extension: undefined,
        marketing_term: props.marketing_term,
      };

      const prevArgs = tokenState.args;

      const args: GoonoRegisterArgs = {
        type: goonoLoginType.SNS,
        args: {
          ...prevArgs.args,
          ...defaultInfo,
          gender: "not-to-disclose",
          user_sign_cid: undefined,
          user_sign_extension: undefined,
        } as IRegister,
      };

      const ret = await goonoRegister(args);
      const { token } = ret;
      const infoRet = await goonoProfile(token);

      await doTokenActionAsync(dispatch, {
        kind: TokenActionKind.TRY_SAVE_USER_INFO,
        token: ret.token,
        ...infoRet.response,
      });
    }
  );
};
