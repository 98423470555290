import { combineReducers } from "redux";
import persist from "./persist";
import token from "./token";
import gdt from "./google_drive_token";
import github from "./github";
import modal from "./modal";
import payment from "./payment";
import snackbar from "./snackbar";
import dialog from "./dialog";
import workspace from "./workspace";
import dataRoomTutorial from "./dataRoomTutorial";
import sidebar from "./sidebar";
import projectInsideScreen from "./projectInsideScreen";
import researchFileUploadScreen from "./researchFileUploadScreen";
import ndaCustomScreen from "./ndaCustomScreen";

const rootReducer = combineReducers({
  persist,
  token,
  gdt,
  github,
  modal,
  payment,
  snackbar,
  dialog,
  workspace,
  dataRoomTutorial,
  sidebar,
  projectInsideScreen,
  researchFileUploadScreen,
  ndaCustomScreen,
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
