import { validate as validateEmail } from "email-validator";
import translate from "@translate";

export type TextValidateResult = {
  available: boolean;
  errorExtractor: (_x: typeof translate) => string;
};

/**
 * 회원 가입 입력 시 유효한 비밀번호 인지 검사
 * @override 특수문자도 포함
 * @param text
 * @return 만약 유효한 텍스트 이면 false, 유효하지 않은 경우 true
 */
export function checkValidatePw(text: string): TextValidateResult {
  if (/[A-Za-z]/.test(text)) {
    if (/\d/.test(text)) {
      if (/[!@#$%^&*(),.?;:']/.test(text)) {
        return {
          available: false,
          errorExtractor: (_x: typeof translate) => "",
        };
      }
    }
  }
  return {
    available: true,
    errorExtractor: (x: typeof translate) => x.error.not_valid_pw,
  };
}

/**
 * 로그인 시 유효한 비밀번호 인지 검사
 * @param text
 * @returns 만약 유효한 텍스트 이면 false, 유효하지 않은 경우 true
 */
export function checkValidatePwForLogin(text: string): TextValidateResult {
  if (/[A-Za-z]/.test(text)) {
    if (/\d/.test(text)) {
      return {
        available: false,
        errorExtractor: (_x: typeof translate) => "",
      };
    }
  }
  return {
    available: true,
    errorExtractor: (x: typeof translate) => x.error.not_valid_login_pw,
  };
}

/**
 * 특수문자 테스트
 * /~_- 를 제외하고는 모두 불가능합니다
 * @param text string
 * @retrun true : 가능한 문자 / false : 불가능한 문자
 */
export function validateSpecialChar(text?: string) {
  if (text === undefined) return true;
  return text.replace(/^[가-힣a-zA-Z0-9\/~_-]+/g, "").length < 1;
}

/**
 * 모든 특수문자 불가능합니다
 * @param text string
 */
export function validateAllSpecialChar(text?: string) {
  if (text === undefined) return true;
  return text.replace(/^[가-힣a-zA-Z0-9]+/g, "").length < 1;
}

/**
 * 이모지 검사
 * 모든 이모지 불가능합니다
 */
export function validateEmojiChar(text: string) {
  return !/(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g.test(
    text
  );
}

/**
 * Goono 통일된 text validator
 * @param text string
 */
export function textValidator(text: string): TextValidateResult {
  if (validateEmojiChar(text) !== true)
    return {
      available: false,
      errorExtractor: (x: typeof translate) => x.error.valid_emoji,
    };
  if (validateSpecialChar(text) === false)
    return {
      available: false,
      errorExtractor: (x: typeof translate) => x.error.valid_special_char,
    };
  return { available: true, errorExtractor: (_x: typeof translate) => "" };
}

export function folderNameValidator(text: string): TextValidateResult {
  if (text.length > 50)
    return {
      available: false,
      // TODO: translate 수정
      errorExtractor: (x: typeof translate) => x.modal.new_folder.condition_err,
    };
  if (text.length < 1)
    return {
      available: false,
      errorExtractor: (x: typeof translate) => x.error.empty_input,
    };
  return { available: true, errorExtractor: (_x: typeof translate) => "" };
}
/**
 * 영문 소문자/대문자/한글 모두 포함함.
 * 자음, 모음만 있는 경우 제외
 * 숫자를 포함함.
 * @param text
 * @returns
 */
export function checkUserName(text: string) {
  if (/^[가-힣a-zA-Z0-9]+/g.test(text)) {
    return true;
  }
  return false;
}

/**
 * 이름 입력 _ 가이드 
1. 한영 모두 가능하나  동시 입력 불가능 ex)박sunny 
2. 한글의 경우
글자로 완성되지 않은 자음, 모음만 기재된 경우/ 연속 사용을 제한함 
ex) ㄱ, ㄴ, ㅣ, ㅏ, ㅏㅏ,ㄴㄴ,ㄷㄹ,ㄱㅔ,ㄴ ㅏ 등
3, 영문의 경우 대소문자의 사용이 자유로움. 동시 사용가능 
ex) SUNNY, Sunny, SuNNy 
4. 공백 입력을 제한
5. 특수문자 사용 안됨 (특수문자 입력해도 인풋에 나타나지않음)
 * @param text string
 */
export function nameValidator(text: string): TextValidateResult {
  if (text.length > 50)
    return {
      available: false,
      errorExtractor: (x: typeof translate) => x.error.maximun_text_length(50),
    };
  if (text.length < 1)
    return {
      available: false,
      errorExtractor: (x: typeof translate) => x.error.empty_input,
    };
  if (checkUserName(text) === false)
    return {
      available: false,
      errorExtractor: (x: typeof translate) => x.error.invalid_text,
    };
  if (validateAllSpecialChar(text) === false)
    return {
      available: false,
      errorExtractor: (x: typeof translate) => x.error.invalid_text,
    };
  if (validateEmojiChar(text) === false)
    return {
      available: false,
      errorExtractor: (x: typeof translate) => x.error.invalid_text,
    };
  return { available: true, errorExtractor: (_x: typeof translate) => "" };
}

export function schoolValidator(text: string): TextValidateResult {
  if (text.length > 50)
    return {
      available: false,
      errorExtractor: (x: typeof translate) => x.error.maximun_text_length(50),
    };
  if (text.length < 1)
    return {
      available: false,
      errorExtractor: (x: typeof translate) => x.error.empty_input,
    };
  if (validateAllSpecialChar(text) === false)
    return {
      available: false,
      errorExtractor: (x: typeof translate) => x.error.invalid_text,
    };
  if (validateEmojiChar(text) === false)
    return {
      available: false,
      errorExtractor: (x: typeof translate) => x.error.invalid_text,
    };
  return { available: true, errorExtractor: (_x: typeof translate) => "" };
}

export function emailValidator(text: string): TextValidateResult {
  if (text.length < 1)
    return {
      available: false,
      errorExtractor: (_x: typeof translate) => "",
    };
  const available = validateEmail(text);
  if (available)
    return { available, errorExtractor: (_x: typeof translate) => "" };
  return {
    available,
    errorExtractor: (x: typeof translate) => x.error.invalid_email,
  };
}

export function validatorNum(text: string): TextValidateResult {
  if (text.length < 1)
    return { available: false, errorExtractor: (_x: typeof translate) => "" };
  if (/\d/.test(text)) {
    if (!/[!@#$%^&*(),.?":{}|<>='/_+[\-\]\`\~\;]/g.test(text)) {
      return { available: false, errorExtractor: (_x: typeof translate) => "" };
    }
  }
  return {
    available: true,
    errorExtractor: (x: typeof translate) => x.error.invalid_number,
  };
}

/**
 * 회원 가입 입력 시 비밀번호 길이 검사
 * @param text
 * @return 만약 유효한 텍스트 이면 false, 유효하지 않은 경우 true
 */
export function checkLengthPW(text: string): TextValidateResult {
  if (text.length >= 8)
    return {
      available: false,
      errorExtractor: (_x: typeof translate) => "",
    };
  return {
    available: true,
    errorExtractor: (x: typeof translate) => x.error.valide_pw_length,
  };
}
