import React from "react";
import translate from "@translate";
import SolidButton from "@ui/goono/components/buttons/SolidButton";
import GoonoTextField from "@ui/goono/components/inputs/GoonoTextField";
import useSignInScreenStyles from "./styles";
import {
  checkLengthPW,
  checkValidatePwForLogin,
  emailValidator,
} from "@utils/functions/input-validator";
import { isEmpty } from "@utils/functions/isEmpty";
import {
  GoonoLoginArgs,
  goonoLoginType,
} from "@goono-react-commons/services/user";

type Props = {
  onSignIn: (args: GoonoLoginArgs) => Promise<void>;
};

export default function SignInScreenEmailLoginForm(props: Props) {
  const classes = useSignInScreenStyles();

  const [email, setEmail] = React.useState("");
  const emailValid = emailValidator(email);

  const [password, setPassword] = React.useState("");
  const passwordValid = checkValidatePwForLogin(password);
  const passwordLengthValid = checkLengthPW(password);

  const emailError = !isEmpty(email) && !emailValid.available;

  const passwordError =
    !isEmpty(password) &&
    (passwordValid.available || passwordLengthValid.available);

  const onSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    await props.onSignIn({
      type: goonoLoginType.EMAIL,
      args: { email, password, platform: "email" },
    });
  };

  return (
    <div style={{ width: `100%` }}>
      <form onSubmit={onSubmit}>
        <GoonoTextField
          dataTestid={"login-user-signin-email-input"}
          value={email}
          inputSize="lg"
          placeholder={translate.placeholder.input_email}
          errorMode={emailError}
          onChange={(evt) => setEmail(evt.target.value.trim())}
          helperText={
            emailError ? emailValid.errorExtractor(translate) : undefined
          }
        />
        <GoonoTextField
          dataTestid={"login-user-signin-password-input"}
          className={classes.password_input}
          value={password}
          inputSize="lg"
          type="password"
          placeholder={translate.placeholder.input_pw}
          errorMode={passwordError}
          onChange={(evt) => setPassword(evt.target.value.trim())}
          helperText={
            passwordError
              ? passwordValid.available
                ? passwordValid.errorExtractor(translate)
                : passwordLengthValid.errorExtractor(translate)
              : undefined
          }
        />
        <SolidButton
          dataTestid={"login-user-signin-submit-button"}
          submit
          buttonType="primary"
          buttonSize="xl"
          className={classes.submit_button}
          children={translate.button.login}
          disabled={
            isEmpty(email) || isEmpty(password) || passwordError || emailError
          }
        />
      </form>
    </div>
  );
}
