import React from "react";
import {
  GoonoLoginArgs,
  goonoLoginType,
} from "@goono-react-commons/services/user";
import { useDispatch } from "react-redux";
import {
  AppleLoginButton,
  GoogleLoginButton,
  KakaoLoginButton,
} from "src/components/public/login";
import useSignInScreenStyles from "./styles";

type Props = {
  onSignIn: (args: GoonoLoginArgs) => Promise<void>;
};

export default function SignInScreenSNSLoginForm(props: Props) {
  const dispatch = useDispatch();
  const classes = useSignInScreenStyles();

  const onSignInSNS = async (
    platform: "kakao" | "google" | "apple",
    token: string
  ) => {
    await props.onSignIn({
      type: goonoLoginType.SNS,
      args: { platform, token },
    });
  };

  return (
    <div className={classes.SNS_form_container}>
      <GoogleLoginButton
        dispatch={dispatch}
        callback={async (token: string) => {
          await onSignInSNS("google", token);
        }}
      />
      <KakaoLoginButton
        dispatch={dispatch}
        callback={async (token: string) => {
          await onSignInSNS("kakao", token);
        }}
      />
      <AppleLoginButton
        dispatch={dispatch}
        callback={async (token: string) => {
          await onSignInSNS("apple", token);
        }}
      />
    </div>
  );
}
