import { createStyles, makeStyles } from "@material-ui/core";
import {
  gds_theme,
  gds_buttons,
  gds_textButtons,
  gds_service,
} from "@ui/goono/styles/theme";

type CSSPropertyValue = string | number;

type StyleObject = {
  [key: string]: CSSPropertyValue | StyleObject;
};

const createButtonVariant = (
  baseStyle: StyleObject,
  hoverStyle: StyleObject,
  activeStyle: StyleObject,
  beforeColor: string,
  afterColor: string
): StyleObject => ({
  ...baseStyle,
  "&::before": { backgroundColor: beforeColor },
  "&::after": { backgroundColor: afterColor },
  "@media (hover: hover)": {
    "&:hover": {
      ...hoverStyle,
    },
  },
  "&:active": {
    ...activeStyle,
  },
  "&:disabled, &.Mui-disabled": {
    "&::before": { backgroundColor: gds_theme.gray_5_body },
  },
});

const useStyles = makeStyles(() =>
  createStyles({
    btn_base: {
      whiteSpace: "nowrap",
      borderRadius: gds_theme.round_3,
      ...gds_theme.btn_transition,
      ...gds_buttons.btn_base,
      "&:disabled, &.Mui-disabled": {
        ...gds_textButtons.btn_disabled,
      },
      "&:hover": { textDecoration: "underline" },
    },

    btn_lg: {
      ...gds_textButtons.size_lg,
      "&::after, &::before": { bottom: 6 },
    },
    btn_md: {
      ...gds_textButtons.size_md,
      "&::after, &::before": { bottom: 5 },
    },
    btn_sm: {
      ...gds_textButtons.size_sm,
      "&::after, &::before": { bottom: 3 },
      "& $icon_base": {
        ...gds_theme.btn_icon_sm,
      },
    },
    underLine: {
      position: "relative",
      paddingBottom: 2,
      background: "none !important",
      "&::before, &::after": {
        content: "''",
        position: "absolute",
        left: 0,
        right: 0,
        height: 2,
        transition: "max-width .25s",
      },
      "&::after": {
        maxWidth: 0,
      },
      "@media (hover: hover)": {
        "&:hover": {
          textDecoration: "unset",
        },
        "&:hover::after": {
          maxWidth: "100%",
        },
      },
    },
    icon_base: {
      ...gds_theme.btn_icon_xl,
      "& img, & svg": {
        maxWidth: "100%",
        maxHeight: "100%",
        fill: "currentColor",
      },
      "& svg *": {
        fill: "inherit",
      },
    },
    icon_start: {
      marginRight: 4,
    },
    icon_end: {
      marginLeft: 4,
    },

    primary: createButtonVariant(
      gds_textButtons.btn_primary,
      gds_textButtons.btn_primary_hover,
      gds_textButtons.btn_primary_active,
      gds_service.primary_100,
      gds_theme.primary
    ),
    black: createButtonVariant(
      gds_textButtons.btn_black,
      gds_textButtons.btn_black_hover,
      {},
      gds_theme.gray_4,
      gds_theme.gray_9_title
    ),
    gray: createButtonVariant(
      gds_textButtons.btn_gray,
      gds_textButtons.btn_gray_hover,
      gds_textButtons.btn_gray_active,
      gds_theme.gray_3,
      gds_theme.gray_6
    ),
  })
);

export default useStyles;
