import { getLoggers } from "@redwit-commons/utils/log";
import { FutureComponent } from "@redwit-react-commons/template/FutureComponent";
import React from "react";
import SNSLoginButton from "../../public/login/SNSLoginButton";
import { ExtendedAppleWindow, AppleAuthOptions } from "./types/TypesApple";
import { connect, ConnectedProps } from "react-redux";

const { ERROR } = getLoggers("AppleLogin");

declare let window: ExtendedAppleWindow;

const mapStateToProps = () => {
  return {};
};

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

type AppleLoginButtonProps = {
  callback: (token: string, email?: string) => void;
  signup?: boolean;
} & PropsFromRedux;

class AppleLoginButton extends FutureComponent<AppleLoginButtonProps> {
  constructor(props: AppleLoginButtonProps) {
    super(props);
  }

  onButtonClick() {
    this.scheduleTask(async () => {
      const params: AppleAuthOptions = {
        clientId: "com.redwitgoono.client",
        scope: "email name",
        redirectURI: "https://www.goono.so/sign-in",
        usePopup: true,
      };
      window.AppleID.auth.init(params);
      try {
        const ret = await window.AppleID.auth.signIn();
        this.props.callback(ret.authorization.id_token);
        return;
      } catch (err) {
        ERROR(err, "onButtonClick", "Apple Auth");
        return;
      }
    });
  }

  render() {
    return (
      <SNSLoginButton
        sns="apple"
        onClick={() => {
          this.onButtonClick();
        }}
        signup={this.props.signup}
      />
    );
  }
}

export default AppleLoginButton;
