import translate from "@translate";
import TextButton from "@ui/goono/components/buttons/TextButton";
import Flex from "@ui/goono/components/layouts/Flex";
import React from "react";
import useSignInScreenStyles from "./styles";
import { useHistory } from "react-router-dom";
import { ScreenURL } from "src/routes/route_list";

export default function SignInScreenFooter() {
  const classes = useSignInScreenStyles();
  const history = useHistory();
  return (
    <Flex
      container
      justifyContent="space-between"
      className={classes.footer_container}
    >
      <TextButton
        dataTestid={"login-redirect-to-sign-up-page"}
        buttonSize="md"
        onClick={() => history.push(ScreenURL.SIGN_UP)}
      >
        {translate.button.register}
      </TextButton>
      <TextButton
        dataTestid={"login-redirect-to-change-password-page"}
        buttonSize="md"
        onClick={() => history.push(ScreenURL.AUTH_PW)}
      >
        {translate.sign_in.change_pw}
      </TextButton>
    </Flex>
  );
}
