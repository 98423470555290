import React from "react";
import { ButtonBase, FormLabel } from "@material-ui/core";
import Flex from "../layouts/Flex";
import useStyles from "./styles/TextButton";
import clsx from "clsx";

export type ButtonType = "primary" | "black" | "gray";
export type ButtonSize = "lg" | "md" | "sm";

export type TextButtonProps = {
  customId?: string;
  /** 버튼 컬러 타입 */
  buttonType?: ButtonType;
  /** 버튼 크기 - height로 판단 [36,32,24] 순서 */
  buttonSize?: ButtonSize;
  /**비활성화 */
  disabled?: boolean;
  className?: string;
  dataTestid?: string;
  onClick?: (evt: React.MouseEvent<HTMLElement>) => void;
  /**form label for 설정 */
  htmlFor?: string;
  /** 내부 아이콘의 Class */
  iconClass?: string;
  /** 좌측 아이콘, svg 추천 */
  startIcon?: React.ReactNode;
  /** 우측 아이콘, svg 추천 */
  endIcon?: React.ReactNode;
  /** 버튼 안의 내용 - 텍스트 */
  children?: React.ReactNode;
  /** 텍스트 하단 라인 여부 */
  underLine?: boolean;
};

/**
 *
 * @param Background & Border 가 없는 버튼
 * @returns
 */
const TextButton: React.FC<TextButtonProps> = ({
  customId,
  buttonType = "black",
  buttonSize = "lg",
  disabled,
  className,
  dataTestid,
  onClick,
  htmlFor,
  iconClass,
  startIcon,
  endIcon,
  children,
  underLine,
}) => {
  const classes = useStyles();

  const sizeClasses: Record<ButtonSize, string> = {
    lg: classes.btn_lg,
    md: classes.btn_md,
    sm: classes.btn_sm,
  };

  const colorClasses: Record<ButtonType, string> = {
    primary: classes.primary,
    black: classes.black,
    gray: classes.gray,
  };

  const component =
    htmlFor && !disabled ? { component: FormLabel, htmlFor } : {};

  return (
    <ButtonBase
      {...component}
      id={customId}
      data-testid={dataTestid}
      disabled={disabled}
      className={clsx(
        classes.btn_base,
        colorClasses[buttonType],
        sizeClasses[buttonSize],
        underLine && classes.underLine,
        className
      )}
      onClick={onClick}
      disableRipple
    >
      {startIcon && (
        <Flex
          container
          alignItems="center"
          justifyContent="center"
          id={customId}
          className={clsx(classes.icon_base, classes.icon_start, iconClass)}
        >
          {startIcon}
        </Flex>
      )}
      {children}
      {endIcon && (
        <Flex
          container
          alignItems="center"
          justifyContent="center"
          id={customId}
          className={clsx(classes.icon_base, classes.icon_end, iconClass)}
        >
          {endIcon}
        </Flex>
      )}
    </ButtonBase>
  );
};

export default TextButton;
