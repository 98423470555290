import React from "react";
import googleIcon from "@assets/images/public/content/sns/googleIcon.svg";
import kakaoIcon from "@assets/images/public/content/sns/kakaoIcon.svg";
import appleIcon from "@assets/images/public/content/sns/appleIcon.svg";
import { Button, Fab } from "@material-ui/core/";
import { createStyles, WithStyles, withStyles } from "@material-ui/core/styles";
import googleLogo from "@assets/images/public/content/sns/googleLogo.svg";
import kakaoLogo from "@assets/images/public/content/sns/kakaoLogo.svg";
import appleLogo from "@assets/images/public/content/sns/appleLogo.svg";
import translate from "@translate";
import { theme } from "@theme";
import GoonoTypography from "@ui/goono/components/typo/GoonoTypography";
import { loadScript } from "@utils/functions/loadScript";

const styles = createStyles({
  button: {
    width: "100%",
    maxWidth: 376,
    marginTop: 14,
    borderRadius: 9,
    marginBottom: 5,
    borderWidth: 0,
    height: 56,
    boxShadow: `0 0 0 0 ${theme.white}`,
    "&:hover": {
      boxShadow: `0 0 0 0 ${theme.white}`,
    },
  },
  inputProps: {
    fontFamily: "NotoSansKR",
    fontSize: 16,
  },
  fab: {
    margin: 9.4,
    width: 65.9,
    height: 65.9,
    boxShadow: `0 0 0 0 ${theme.white}`,
  },
});

type SNS = "kakao" | "google" | "apple";

type SNSLoginButtonProps = {
  sns: SNS;
  signup?: boolean;
  onClick: () => void;
} & WithStyles<typeof styles>;

type SNSLoginButtonState = {
  /** sdk script 로드 됬는지 확인용 flag */
  loadedSdkScript: boolean;
};

class SNSLoginButton extends React.PureComponent<
  SNSLoginButtonProps,
  SNSLoginButtonState
> {
  private sdkScriptUrl =
    this.props.sns === "apple"
      ? "https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js"
      : this.props.sns === "google"
      ? "https://apis.google.com/js/platform.js?onload=init"
      : this.props.sns === "kakao"
      ? "https://developers.kakao.com/sdk/js/kakao.min.js"
      : "unknown";

  static defaultProps = {
    onClick: () => {},
  };

  constructor(props: SNSLoginButtonProps) {
    super(props);
    this.state = {
      loadedSdkScript: false,
    };
  }

  componentDidMount(): void {
    loadScript({
      script_option: { src: this.sdkScriptUrl },
      onload: () => this.setState({ loadedSdkScript: true }),
    });
  }

  snsIcon(sns: SNS) {
    switch (sns) {
      case "kakao":
        return kakaoIcon;
      case "google":
        return googleIcon;
      case "apple":
        return appleIcon;
    }
  }

  snsLogo(sns: SNS) {
    switch (sns) {
      case "kakao":
        return kakaoLogo;
      case "google":
        return googleLogo;
      case "apple":
        return appleLogo;
    }
  }

  snsButtonText(sns: SNS) {
    switch (sns) {
      case "kakao":
        return translate.button.login_platform.kako;
      case "google":
        return translate.button.login_platform.google;
      case "apple":
        return (
          <span style={{ color: "white" }}>
            {translate.button.login_platform.apple}
          </span>
        );
    }
  }

  snsStyles(sns: SNS) {
    switch (sns) {
      case "kakao":
        return { backgroundColor: "#ffe500" };
      case "google":
        return {
          border: `solid 1.2px ${theme.gray_2}`,
          backgroundColor: "#fff",
        };
      case "apple":
        return { backgroundColor: theme.gray_9_title };
    }
  }

  render() {
    const { sns, onClick, classes } = this.props;

    if (this.props.signup) {
      return (
        <Button
          disabled={this.state.loadedSdkScript === false}
          variant={sns === "google" ? "outlined" : "contained"}
          onClick={onClick}
          className={classes.button}
          startIcon={<img src={this.snsLogo(sns)} />}
          style={{
            ...this.snsStyles(sns),
            opacity: this.state.loadedSdkScript === false ? 0.7 : 1,
          }}
        >
          <GoonoTypography type="b2_m">
            {this.snsButtonText(sns)}
          </GoonoTypography>
        </Button>
      );
    }
    return (
      <Fab
        data-testid={`social-login-button-${sns}`}
        className={classes.fab}
        disabled={this.state.loadedSdkScript === false}
      >
        <img
          onClick={() => onClick()}
          src={this.snsIcon(sns)}
          style={{
            width: 65.9,
            opacity: this.state.loadedSdkScript === false ? 0.7 : 1,
          }}
        />
      </Fab>
    );
  }
}

export default withStyles(styles)(SNSLoginButton);
