import React from "react";
import SNSLoginButton from "../../public/login/SNSLoginButton";
import { ExtendedWindow } from "./types/TypesKakao";
import { getLoggers } from "@redwit-commons/utils/log";
import { FutureComponent } from "@redwit-react-commons/template/FutureComponent";
import { connect, ConnectedProps } from "react-redux";

const { ERROR } = getLoggers("KakaoLogin");

declare let window: ExtendedWindow;

const mapStateToProps = () => {
  return {};
};

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

type KakaoLoginButtonProps = PropsFromRedux & {
  callback?: (token: string, email: string) => void;
  signup?: boolean;
};

class KakaoLoginButton extends FutureComponent<KakaoLoginButtonProps> {
  constructor(props: KakaoLoginButtonProps) {
    super(props);
  }

  private jsKey = "834d75d0b5a7a701a920cabd3758d5d2";

  private onButtonClick = () => {
    this.scheduleTask(() => {
      if (window.Kakao.Auth === undefined) {
        window.Kakao.init(this.jsKey);
      }

      window.Kakao.Auth.loginForm({
        throughTalk: false,
        persistAccessToken: false,
        success: (response) => {
          window.Kakao.API.request({
            url: "/v2/user/me",
            success: (profile) => {
              const { callback } = this.props;
              if (callback === undefined) return;
              return callback(
                response.access_token,
                profile.kakao_account.email
              );
            },
            fail: ERROR,
          });
        },
        fail: ERROR,
      });
    });
  };

  render() {
    return (
      <SNSLoginButton
        sns="kakao"
        onClick={() => {
          this.onButtonClick();
        }}
        signup={this.props.signup}
      />
    );
  }
}

export default KakaoLoginButton;
